<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col offset-md="1" offset-lg="2">
        <Container :paddedTopBottom="false">
          <h2>Serge Boussen</h2>
          <p>
            Na mijn vierjarige sportopleiding en tweejarige
            massagetherapiestudie merkte ik dat ik me verder wilde verdiepen in
            het menselijk lichaam. Zelf had ik al goede ervaringen bij een
            osteopaat. Ik vind het fijn om mensen te helpen. Wanneer er klachten
            ontstaan kan dit beperkend zijn voor het functioneren van mensen.
            Als osteopaat kan ik bijdragen aan de levenskwaliteit van mensen
            door klachten te verminderen of te verhelpen.
          </p>
          <p>
            Na mijn osteopathiestudie aan de IAO (International Academy of
            Osteopathy) te Gent, ben ik verzeild geraakt in Zwitserland. Ik heb
            hier 3 jaar lang veel ervaring opgedaan in het behandelen van zowel
            volwassenen, kinderen als baby’s.
          </p>
          <p>
            De afgelopen jaren heb ik me bijgeschoold door het volgen van de
            cursussen “Geïntegreerde Technieken" volgens de Bakker. Hierbij
            wordt er voornamelijk gekeken naar het ontstaan van klachten in
            samenhang met de embryologische ontwikkeling van het lichaam. Hier
            zal ik me nog verder in specialiseren voor het behandelen van
            baby’s, kinderen en volwassenen.
          </p>
          <p>
            Mijn doel is om als osteopaat in de topsport te werken en zo een
            bijdrage te leveren aan de prestatie van de (top)sporter. De eerste
            stap is gezet, ik behandel momenteel de voetballers van Willem II.
            Topsporters staan altijd onder grote druk. Ondanks ze soms niet
            volledig fit zijn moeten ze blijven presteren. Er is geen tijd voor
            rust. Het geeft mij voldoening, wanneer ik een geblesseerde
            topsporter met de juiste begeleiding weer zo snel mogelijk op het
            oude niveau kan krijgen.
          </p>
          <p>
            In mijn vrije tijd ga ik graag een stukje mountainbiken of wandelen
            met mijn gezin in de prachtige Brabantse bossen.
          </p>
          <h3>Specialismen</h3>
          <p>
            In de praktijk behandel ik ook baby's. Baby's die last hebben van
            krampjes of bijvoorbeeld een voorkeurshouding van het hoofd. Ook het
            behandelen van onrustige baby's is mijn specialisme. Osteopathie kan
            ook helpen bij baby's die slecht de borst en/of fles pakken, dit
            komt ook regelmatig voor.
          </p>
          <p>
            Door mijn affiniteit met sport behandel ik ook veel (top)sporters
            met blessures en preventie daarvan. Sporters behandel ik vaak
            volgens het Fasciaal Distorsie Model. Volgens dit model kunnen
            verdraaiingen in het onderhuids bindweefsel tot vervelende
            sportblessures leiden. Door deze verdraaiingen te behandelen kunnen
            de blessures gedeeltelijk of zelfs geheel verdwijnen. Denk hierbij
            aan chronische blessures, maar ook na een acuut trauma
            (enkeldistorsie, zweepslag) kun je bij ons terecht!
          </p>
          <h3>Opleiding en Nascholing</h3>
          <ul>
            <li>The Internation Academy of Osteopathy in Gent</li>
            <li>Geïntegreerde Technieken volgens de Bakker (Baby's)</li>
            <li>
              Dysfuncties van het kaakgewricht in relatie tot het ontstaan van
              sportblessures
            </li>
            <li>Fasciaal Distorsie Model</li>
            <li>Sportosteopathie (af te ronden in 2021)</li>
          </ul>

          <img src="../../assets/team/serge.png" alt="Serge" />
        </Container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Container from "../../components/Container.vue";
export default {
  name: "",
  components: { Container }
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

h3 {
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
  color: #747769;
  em {
    font-style: normal;
    color: #747769;
  }
}

img {
  max-width: 100%;
}
</style>
